import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { css } from '@emotion/core';

import { ImageText, Modal } from '@myungsoo/components';
import { BaseLayout, Title } from '@myungsoo/base/components';

import titleImage from '../images/title.svg';

const Layout = ({ children, ...restProps }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `,
  );

  return (
    <BaseLayout
      css={css`
        ${Modal} {
          z-index: 5;
        }
      `}
      renderTitle={() => (
        <Title render={Link} to="/">
          <ImageText src={titleImage}>{site.siteMetadata.title}</ImageText>
        </Title>
      )}
      menuItems={[
        { key: 'about', to: '/about', children: 'About' },
        { key: 'reel', to: '/reel', children: 'Reel' },
        { key: 'news', to: '/news', children: 'News' },
        { key: 'contact', to: '/contact', children: 'Contact' },
      ]}
      renderFooter={() => (
        <footer
          css={css`
            padding: 1rem;
            text-align: center;
          `}
        >
          <ImageText src={titleImage}>{site.siteMetadata.title}</ImageText>
        </footer>
      )}
      {...restProps}
    >
      {children}
    </BaseLayout>
  );
};

export default Layout;
